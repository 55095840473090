import * as React from "react"
import { navigate } from "gatsby"
import { isMobile } from "react-device-detect"
import { useWindowSize } from "react-use"

import Seo from "@src/components/seo"
import Billiards from "@src/components/billiards"

const isBrowser = () => typeof window !== "undefined"
const maxMobileWidth = "768"; // モバイル版ではないと判断する最大幅

/**
 * モバイル版ではない?
 * @param {number} width 
 * @returns yes or no
 */
const isNotMobile = (width) => {
  return !isMobile || width >= maxMobileWidth;
}

const IndexPage = () => {
  const [mobile, setMobile] = React.useState(false);
  const { width, height } = useWindowSize();
  React.useEffect(() => {
    /**
     * 画面サイズが変わるたびに、モバイル版かの判定を行う
     */
    if (isBrowser() && isNotMobile(window.innerWidth)) {
      navigate("home/");
      setMobile(false)
    } else {
      setMobile(true);
    }
  }, [width, height])

  if (!mobile) {
    return (
      <>
        <Seo title="Welcome" />
      </>
    )
  } else {
    return(
      <>
        <Seo title="Welcome" />
        <Billiards />
      </>
    )
  }
}

export default IndexPage
