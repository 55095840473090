import * as React from "react";
import { navigate } from "gatsby";

import Platform from "../../images/billiards/platform.png";
import MarketingBall from "@src/images/billiards/operation-color.png"
import CreationBall from "@src/images/billiards/creation-color.png"
import HomeBall from "@src/images/billiards/home-ball.png"
import InnovationBall from "@src/images/billiards/revolution-color.png"
import CompanyBall from "@src/images/billiards/Company-color.png"
import Arrow from "@src/images/billiards/arrow.png"
import WhiteBall from "@src/images/billiards/ball.png"
import Cue from "@src/images/billiards/cue.png"

import "./style.css";

/**
 * ページ遷移先
 */
const links = ["marketing/", "creation/", "home/", "innovation/", "company-info/"];

const initialState = {
    firstY: -1,
    isFlip: false,
    isPlay: false,
};

/**
 * PC版未満で表示させるビリヤード
 */
const Billiards = () => {
    const [firstY, setFirstY] = React.useState(initialState.firstY); // クリックした時のY座標
    const [isFlip, setIsFlip] = React.useState(initialState.isFlip); // ボールを弾いてからページ遷移までの間に、複数クリック対策
    const [isPlay, setIsPlay] = React.useState(initialState.isPlay);

    const arrowRef = React.useRef(null);
    const ballRef = React.useRef(null);
    const ballWrapRef = React.useRef(null); // 色付きボールを囲むラッパーのref
    const cueRef = React.useRef(null);

    React.useEffect(() => {
        /**
         * 指を動かしたらボールに狙いを定める
         * @param {React.MouseEvent | React.TouchEvent} event 
         */
        const handleMove = (event) => {
            if (isPlay) {
                // 狙っているボールのindex取得
                const x = event.clientX || event.changedTouches[0].clientX;
                const index = aimBall(x);

                // キューと矢印の移動アニメーション
                switch (index) {
                    case 0:
                        arrowRef.current.style.transform = `translate(-50%) rotate(-30deg)`;
                        cueRef.current.style.transform = `translateX(-50%) rotate(-30deg)`;
                        break;
                    case 1:
                        arrowRef.current.style.transform = `translate(-50%) rotate(-15deg)`;
                        cueRef.current.style.transform = `translateX(-50%) rotate(-15deg)`;
                        break;
                    case 3:
                        arrowRef.current.style.transform = `translate(-50%) rotate(15deg)`;
                        cueRef.current.style.transform = `translateX(-50%) rotate(15deg)`;
                        break;
                    case 4:
                        arrowRef.current.style.transform = `translate(-50%) rotate(30deg)`;
                        cueRef.current.style.transform = `translateX(-50%) rotate(30deg)`;
                        break;
                    case 2:
                    default:
                        arrowRef.current.style.transform = `translate(-50%) rotate(0deg)`;
                        cueRef.current.style.transform = `translateX(-50%) rotate(0deg)`;
                        break;
                }

                // ボールを狙おうとしているか?
                if (isPull(event)) {
                    cueRef.current.style.bottom = `calc(0px - 150px - 20px)`;
                } else {
                    cueRef.current.style.bottom = `calc(0px - 150px - 5px)`;
                }
            }
        }
        
        /**
         * 指を離したらボールを弾く
         * @param {React.MouseEvent | React.TouchEvent} event 
         */
        const handleUp = (event) => {
            // ページ遷移中のhandleMove()対策
            setIsPlay(initialState.isPlay);
            setFirstY(initialState.firstY);

            // ゲーム中 かつ 弾こうとしているか?
            if (isPlay === true && isPull(event)) {
                const x = event.clientX || event.changedTouches[0].clientX;
                const index = aimBall(x);
                flipBall(index);
            }
        }

        
        /**
         * ボールを弾こうとしているか?
         * @param {React.MouseEvent | React.TouchEvent} event 
         * @returns yes or no
         */
        const isPull = (event) => {
            const clientY = event.clientY || event.changedTouches[0].clientY;
            return firstY < clientY;
        }

        // イベント登録
        window.addEventListener("mousemove", handleMove);
        window.addEventListener("touchmove", handleMove);
        window.addEventListener("mouseup", handleUp);
        window.addEventListener("touchend", handleUp);
        return () => {
            // イベントの多重登録回避
            window.removeEventListener("mousemove", handleMove);
            window.removeEventListener("touchmove", handleMove);
            window.removeEventListener("mouseup", handleUp);
            window.removeEventListener("touchend", handleUp);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPlay, firstY]);

    /**
     * ビリヤードの開始
     * @param {React.MouseEvent | React.TouchEvent} event 
     */
    const handleTouchStart = (event) => {
        setIsPlay(true);
        setFirstY(event.clientY || event.changedTouches[0].clientY)
    }

    /**
     * 狙う先のボールを決める
     *  例: X座標が0(ポインタが左端)なら一番右に飛ばす
     * @param {number} x X座標 
     * @returns links の index
     */
    const aimBall = (x) => {
        const clientWidth = window.innerWidth;
        const length = links.length;

        // 画面をlength(個)分割する
        const perPieceWidth = clientWidth / length;
        for (let i = 0; i < length - 1; i++) {
            if (x < perPieceWidth * (i + 1)) {
                return length - 1 - i;
            }
        }
        return 0;
    }

    /**
     * ボールを弾く
     * @param {number} index linksのindex
     */
    const flipBall = (index) => {
        if (isFlip) return;

        setIsFlip(true);
        // ページ遷移中のhandleMove()対策
        setIsPlay(initialState.isPlay);
        setFirstY(initialState.firstY);

        // ボールを弾くアニメーション
        arrowRef.current.style.display = `none`;
        cueRef.current.style.bottom = `calc(0px - 150px + 5px)`;
        switch (index) {
            case 0:
                ballRef.current.style.bottom = `calc(50% - 40px - 35px)`;
                ballRef.current.style.left = `calc(10% + 40px)`;
                break;
            case 1:
                ballRef.current.style.bottom = `calc(90% - 61px - 50px)`;
                ballRef.current.style.left = `calc(10% + 40px + 25px)`;
                break;
            case 3:
                ballRef.current.style.bottom = `calc(90% - 61px - 50px)`;
                ballRef.current.style.left = `calc(90% - 40px)`;
                break;
            case 4:
                ballRef.current.style.bottom = `calc(50% - 40px - 45px)`;
                ballRef.current.style.left = `calc(90% - 40px)`;
                break;
            case 2:
            default:
                ballRef.current.style.bottom = `calc(70% - 80px - 40px)`;
            break;
        }

        // 弾くアニメーションが終わるまでページ遷移は待機させる
        setTimeout(() => {
            navigate(links[index]);
            return null;
        }, 0.5 * 1000)
    }


    return(
        <div className="billiards-wrap" style={{backgroundImage: `url(${Platform})`}}>
            <div className="ball-wrap" ref={ballWrapRef}>
                <div className="ball-operation-wrap" onTouchStart={() => flipBall(0)}>
                    <img src={MarketingBall} alt="運用事業部へのリンク付きボール" className="ball-operation ball-operation-color" style={{width: `100px`, height: `65px`, objectFit: `contain`}} />
                    {/* <StaticImage src="../../images/billiards/operation-color.png" alt="運用事業部へのリンク付きボール" className="ball-operation ball-operation-color" objectFit="contain" style={{width: `100px`, height: `65px`}} /> */}
                </div>
                <div className="ball-creation-wrap" onTouchStart={() => flipBall(1)}>
                    <img src={CreationBall}  alt="創造事業部へのリンク付きボール"className="ball-creation ball-creation-color" style={{width: `100px`, height: `65px`, objectFit: `contain`}} />
                    {/* <StaticImage src="../../images/billiards/creation-color.png" alt="創造事業部へのリンク付きボール" className="ball-creation ball-creation-color" objectFit="contain" style={{width: `100px`, height: `65px`}} /> */}
                </div>
                <div className="ball-home-wrap" onTouchStart={() => flipBall(2)}>
                    <img src={HomeBall} alt="HOMEへのリンク付きボール" className="ball-home" style={{width: `115px`, height: `80px`, objectFit: `contain`}} />
                    {/* <StaticImage src="../../images/billiards/home-ball.png" alt="HOMEへのリンク付きボール" className="ball-home" objectFit="contain" style={{width: `115px`, height: `80px`}} /> */}
                </div>
                <div className="ball-revolution-wrap" onTouchStart={() => flipBall(3)}>
                    <img src={InnovationBall} alt="革命事業部へのリンク付きボール" className="ball-revolution ball-revolution-color" style={{width: `100px`, height: `65px`, objectFit: `contain`}} />
                    {/* <StaticImage src="../../images/billiards/revolution-color.png" alt="革命事業部へのリンク付きボール" className="ball-revolution ball-revolution-color" objectFit="contain" style={{width: `100px`, height: `65px`}} /> */}
                </div>
                <div className="ball-company-wrap" onTouchStart={() => flipBall(4)}>
                    <img src={CompanyBall} alt="会社概要へのリンク付きボール" className="ball-company ball-company-color" style={{width: `100px`, height: `65px`, objectFit: `contain`}} />
                    {/* <StaticImage src="../../images/billiards/company-color.png" alt="会社概要へのリンク付きボール" className="ball-company ball-company-color" objectFit="contain" style={{width: `100px`, height: `65px`}} /> */}
                </div>
            </div>
            <div className="arrow-wrap" ref={arrowRef}>
                <img src={Arrow} alt="ビリヤードの方向" style={{ height: '100px'}} />
                {/* <StaticImage height={100} src="../../images/billiards/arrow.png" alt="arrow" /> */}
            </div>
            <div className="ball-white-wrap" ref={ballRef}>
                <img src={WhiteBall} alt="ビリヤードのボール" className="ball-white" style={{ height: '40px', pointerEvents: "none"}} />
                {/* <StaticImage height={40} src="../../images/billiards/ball.png" alt="white ball" className="ball-white" style={{pointerEvents: "none"}} /> */}
            </div>
            <div className="cue-wrap" ref={cueRef} onTouchStart={(event) => handleTouchStart(event)}>
                <img src={Cue} alt="ビリヤードのキュー" className="cue" style={{height: '300px', pointerEvents: "none"}} />
                {/* <StaticImage height={300} src="../../images/billiards/cue.png" alt="cue" className="cue" objectFit="contain" style={{pointerEvents: "none"}} /> */}
            </div>
        </div>
    )
}

export default Billiards;